import DevelopmentConfig from "./config.development";
/**
 * The main configuration object for the full project.
 *
 * The file src/config.example.js may be copied to src/config.js as the example file will not be included anywhere.
 *
 * @type {Object}
 */
let Config = {
  /**
   * Flag if debugging is active or not.
   *
   * @type {boolean}
   */
  debug: true,

  /**
   * Settings for communicating with the connected API.
   *
   * @type {Object}
   */
  api: {
    /**
     * The base URL the API is callable from.
     *
     * @type {string}
     */
    base_url: "https://api.die-etagen.de",

    /**
     * Authentication settings.
     *
     * Note: These settings will be used only as long as no authentcation credentials are given by the user.
     *
     * @type {Object}
     */
    auth: {
      /**
       * The username to use to authenticate against the API.
       *
       * @type {string}
       */
      username: "etagen",

      /**
       * The password for the user.
       *
       * @type {string}
       */
      password: "Jqq/xhUjfo/uB5#61v4G5,j"
    },

    /**
     * The API version to use.
     *
     * For more details, have a look at EtagenApi.version.
     *
     * @type {Number}
     */
    version: 1,

    /**
     * The abstraction layer to use.
     *
     * Note: For the entered layer here there mus exist a layer Object.
     *
     * Allowed values so far:<br />
     * <code>["drupal"]</code>
     *
     * @type {string}
     */
    layer: "drupal",

    /**
     * Indicates whether the data fetched from the API should be preprocessed.
     *
     * Set this to "true" only if the API supports that!
     *
     * @type {boolean}
     */
    preprocessed: true,

    /**
     * Indicates whether or not to resolve associated content entities.
     *
     * Associated content entities are entities that are bound to other entities through a tag-tagged relationship.
     * Example:
     * You have a tag named "foo". Associated content entities are these content entities which are tagged with the tag
     * "foo".
     */
    resolveAssociated: true
  },

  /**
   * Cache configuration.
   *
   * @type {Object}
   */
  cache: {
    /**
     * Indicates whether caching is enabled or not.
     *
     * @type {boolean}
     */
    enabled: true
  },

  /**
   * The theme to use.
   *
   * The default theme is always enabled and if no other theme is specified here, the default theme will be the only
   * theme used.
   *
   * @type {string}
   */
  theme: "default"
};

// Override config in dev env.
if (process.env.NODE_ENV === "development") {
  Config = DevelopmentConfig;
}

export default Config;
