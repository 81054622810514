<template>
  <div id="app">
    <HeaderBar></HeaderBar>
    <router-view />
    <!--
    <router-link to="/noapi/home">Home</router-link> | <router-link to="/noapi/about">About</router-link> |
    <router-link to="/noapi/elements">Elements</router-link> | <router-link to="/noapi/test">Tests</router-link>
    -->
    <NavigationSidebar></NavigationSidebar>
    <FixedBottomButtons></FixedBottomButtons>
    <CookieBanner></CookieBanner>
  </div>
</template>

<script>
import { EtagenApi } from "./etagen-api";
import HeaderBar from "./components/HeaderBar";
import NavigationSidebar from "@/components/NavigationSidebar";
import FixedBottomButtons from "@/components/FixedBottomButtons";
import CookieBanner from "./components/CookieBanner";

export default {
  name: "App",
  components: {CookieBanner, NavigationSidebar, HeaderBar, FixedBottomButtons },
  metaInfo: {
    title: "Willkommen",
    titleTemplate: "%s | Die Etagen GmbH",
    meta: []
  },
  data() {
    return {
      links: [],
      isOnline: true
    };
  },
  created() {
    EtagenApi.bootstrapByConfig();
  },
  methods: {
    // checkOnlineStatus() {
    //   //console.info("Started checkOnlineStatus");
    //   EtagenApi.callEndpoint("check-online-status")
    //     .then(result => {
    //       if (result.status === "OK") {
    //         if (!this.isOnline) {
    //           this.$bvToast.toast("Die Verbindung zum Server wurde wieder hergestellt.", {
    //             variant: "success",
    //             title: "Verbunden!"
    //           });
    //         }
    //         this.isOnline = true;
    //       }
    //     })
    //     .catch(() => {
    //       //console.error(error.message);
    //       if (this.isOnline) {
    //         this.$bvToast.toast("Es konnte keine Verbindung zum Server hergestellt werden.", {
    //           variant: "danger",
    //           title: "Netzwerkfehler!"
    //         });
    //       }
    //       this.isOnline = false;
    //     });
    // }
  }
};
</script>

<style lang="scss">
.header-bar{
  .header-bar-full-menu.hidden {
    opacity: 0;
    transition: opacity 1s;
  }
}
</style>
