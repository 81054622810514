import Config from "../config";

/**
 * Object that provides several methods and attributes which cannot or must not be split into own objects.
 *
 * @type {Object}
 */
export const Etagen = {
  /**
   * Flag whether or not this application runs in debug mode or not.
   *
   * @type {boolean}
   */
  debug: Config.debug,

  /**
   * Sets the default format used for the moment datetime library.
   *
   * @type {String}
   */
  defaultMomentFormat: "HHmmssSSS",

  /**
   * Renders a bootstrap-based error message (Toast in danger variant to be exactly).
   *
   * @param component {Object}
   *   The component to render the message on/in.
   * @param message {string}
   *   The message to show.
   */
  displayError(component, message) {
    component.$bvToast.toast(message, {
      title: "Fehler",
      variant: "danger"
    });
  },

  /**
   * A sub-object that holds some nice functions used during rendering.
   *
   * @type {Object}
   */
  renderer: {
    /**
     * Pass the background color/flavor to this function and you will get the corresponding bootstrap CSS class in
     * return.
     *
     * @param backgroundColor {string}
     *   The background flavor, like "light", "middle" or "primary".
     * @returns {string}
     *   The CSS class provided by bootstrap.
     */
    setBackgroundColor(backgroundColor) {
      let newBackgroundColor = "";
      switch (backgroundColor) {
        case "middle":
          newBackgroundColor = "bg-secondary";
          break;
        default:
          newBackgroundColor = `bg-${backgroundColor}`;
          break;
      }

      return newBackgroundColor;
    },

    /**
     * Returns the transparent flavor of the given background-color.
     *
     * @param backgroundColor {string}
     *   The current background color class.
     * @returns {string}
     *   The current background color class appended by "-transparent".
     */
    getTransparentBackgroundColor(backgroundColor) {
      return `${backgroundColor}-transparent`;
    },

    /**
     * Builds the CSS declaration to display a background image based on the given URL.
     *
     * @param url {string}
     *   The image URL.
     * @returns {string}
     *   The CSS declaration to display the given image as background image.
     */
    buildBackgroundImageStyle(url) {
      return `background-image: url("${url}");`;
    },

    /**
     * Converts the given width string ("wide", "narrow" for example) to the corresponding CSS class.
     *
     * @param widthString {string}
     *   The current width string.
     * @returns {string}
     *   The CSS class for the given width.
     */
    setContentWidth(widthString) {
      let width = "w-75";
      switch (widthString) {
        case "wide":
          width = "w-100";
          break;
        default:
          width = "w-75 mx-auto";
          break;
      }
      return width;
    },

    reworkTeasers(refs, config) {
      let referenced = [];
      if (refs.length) {
        for (let i = 0; i < refs.length; i++) {
          let reference = {};
          let ref = refs[i];
          if (ref !== undefined) {
            if (ref.teaserImage !== undefined) {
              let teaserImage = ref.teaserImage.images[0];

              if (config.useTeaserImageStyle !== null && teaserImage.styles[config.useTeaserImageStyle] !== undefined) {
                reference.image = teaserImage.styles[config.useTeaserImageStyle].url;
              } else {
                reference.image = teaserImage.url;
              }
            }

            if (ref.title !== undefined) {
              reference.title = ref.title;
            }

            if (ref.teaserText !== undefined) {
              reference.text = ref.teaserText;

              if (config.truncateLongText && config.truncationLength > 0) {
                if (reference.text.length > config.truncationLength) {
                  reference.text = reference.text.truncate(config.truncationLength, true);
                }
              }
            }

            if (ref.path !== undefined) {
              reference.url = ref.path;
            }
          }

          referenced.push(reference);
        }
      }

      return referenced;
    },

    reorderGridTeaser(referenced, itemsPerRow) {
      let rows = [];
      if (referenced.length) {
        let j = 0;
        for (let i = 0; i < referenced.length; i++) {
          if (i % itemsPerRow === 0 && i >= itemsPerRow) {
            j++;
          }
          if (rows[j] === undefined) {
            rows[j] = [];
          }
        }

        let r = 0;
        for (let i = 0; i < referenced.length; i++) {
          if (i % itemsPerRow === 0 && i >= itemsPerRow) {
            r++;
          }
          rows[r].push(referenced[i]);
        }
      }

      return rows;
    },

    reorderMasonryTeaser(referenced, itemsPerRow) {
      let rows = [];
      if (referenced.length) {
        for (let i = 0; i < itemsPerRow; i++) {
          rows[i] = [];
        }

        for (let i = 0; i < referenced.length; i++) {
          let j;
          j = i;
          if (i >= itemsPerRow) {
            j = i - itemsPerRow;
          }
          rows[j].push(referenced[i]);
        }

        let oldRows = rows;
        rows = [[]];

        for (let i = 0; i < oldRows.length; i++) {
          for (let j = 0; j < oldRows[i].length; j++) {
            rows[0].push(oldRows[i][j]);
          }
        }
      }

      return rows;
    },

    reorderSliderTeaser(referenced) {
      let rows = [];
      if (referenced.length) {
        rows = [[]];

        for (let i = 0; i < referenced.length; i++) {
          rows[0].push(referenced[i]);
        }
      }

      return rows;
    },

    reorderTeaser(layout, referenced, itemsPerRow) {
      switch (layout) {
        case "grid":
          return this.reorderGridTeaser(referenced, itemsPerRow);

        case "masonry":
          return this.reorderMasonryTeaser(referenced, itemsPerRow);

        case "slider":
          return this.reorderSliderTeaser(referenced);
      }
    }
  },

  empty(variable) {
    return variable !== undefined && variable !== null && variable !== "";
  },

  importThemes() {
    require("@/themes/default/index.scss");
    if (!this.empty(Config.theme)) {
      require(`@/themes/${Config.theme}/index.scss`);
    }
  }
};
