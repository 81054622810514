import { EtagenApi } from "../index";

/**
 * API wrapper to use with Drupal.
 *
 * This object brings some functions to resolve Nodes, Taxonomy Terms, etc.
 *
 * @type {Object}
 */
const EtagenDrupalApi = {
  /**
   * Calls the api.
   *
   * This method will be the only one calling the actual API to fetch contents.
   *
   * @param {string} langcode
   *   The code of the language the content should be fetched in.
   * @param {string} type
   *   The entity type, for example "node" or "taxonomy_term".
   * @param {string} tags
   *   Tags the content needs to have.
   * @param {string} bundle
   *   The bundle of the fetched content. For "node" as entity type this can be "page" for example.
   * @param {string} id
   *   The id of the entity to fetch.
   * @param {Object} body
   *   Parameters which are passed as POST body contents.
   *
   * @returns {Promise<EtagenApi>}
   *   A promise to resolve the fetched entities.
   */
  getByArgs(langcode = "default", type = "all", tags = "all", bundle = "all", id = "all", body = {}) {
    // If ${tags} is passed as an array, we need to implode that with "+" as separator.
    // In the end we have converted an array like ["lorem", "ipsum"] into a string like "lorem+ipsum".
    if (Array.isArray(tags)) {
      tags = tags.join("+");
    }

    // Build the endpoint to call.
    let endpoint = `get/${langcode}/${type}/${tags}/${bundle}/${id}`;

    return new Promise((resolve, reject) => {
      EtagenApi.callEndpoint(endpoint, body)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /**
   * Fetches all contents of the given entity type id.
   *
   * @param {string} type
   *   The entity type to fetch. For example: "node", "taxonomy_term", "paragraph".
   *
   * @returns {Promise<EtagenApi>}
   *   A promise to resolve the fetched entities.
   */
  getAllByType(type) {
    return new Promise((resolve, reject) => {
      this.getByArgs("default", type)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /**
   * Fetches all entities by the given type and filter them by the id.
   *
   * @param {string} type
   *   The entity type.
   * @param {int} id
   *   The id to search for.
   *
   * @returns {Promise<EtagenDrupalApi>}
   */
  getOneByTypeAndId(type, id) {
    return new Promise((resolve, reject) => {
      this.getByArgs("default", type, "all", "all", id, { count: 1 })
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /**
   * Fetches all nodes.
   *
   * @returns {Promise<EtagenDrupalApi>}
   *
   * @see https://www.drupal.org/docs/core-modules-and-themes/core-modules/node-module/about-nodes
   * @see https://api.drupal.org/api/drupal/core%21modules%21node%21src%21Entity%21Node.php/class/Node/9.1.x
   */
  getAllNodes() {
    return new Promise((resolve, reject) => {
      this.getAllByType("node")
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /**
   * Fetches one node qualfied by its id.
   *
   * @param {int} nodeId
   *   The node id.
   *
   * @returns {Promise<EtagenDrupalApi>}
   */
  getNodeById(nodeId) {
    return new Promise((resolve, reject) => {
      this.getOneByTypeAndId("node", nodeId)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /**
   * Get all taxonomy terms.
   *
   * @returns {Promise<EtagenDrupalApi>}
   */
  getAllTaxonomyTerms() {
    return new Promise((resolve, reject) => {
      this.getAllByType("taxonomy_term")
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /**
   * Get one taxonomy term qualified by its id.
   *
   * @param {Number} termId
   *   The taxonomy term id.
   *
   * @returns {Promise<EtagenDrupalApi>}
   */
  getTaxonomyTermById(termId) {
    return new Promise((resolve, reject) => {
      this.getOneByTypeAndId("taxonomy_term", termId)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /**
   * Get all saved paragraphs.
   *
   * @returns {Promise<EtagenDrupalApi>}
   */
  getAllParagraphs() {
    return new Promise((resolve, reject) => {
      this.getAllByType("paragraph")
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /**
   * Get one single paragraph qualified by its id.
   *
   * @param {Number} paragraphId
   *   The id of the paragraph to fetch.
   *
   * @returns {Promise<EtagenDrupalApi>}
   */
  getParagraphById(paragraphId) {
    return new Promise((resolve, reject) => {
      this.getOneByTypeAndId("paragraph", paragraphId)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /**
   * Get all users.
   *
   * @returns {Promise<EtagenDrupalApi>}
   */
  getAllUsers() {
    return new Promise((resolve, reject) => {
      this.getAllByType("user")
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /**
   * Fetches one single user qualified by its id.
   *
   * @param {Number} userId
   *   The user id.
   *
   * @returns {Promise<EtagenDrupalApi>}
   */
  getUserById(userId) {
    return new Promise((resolve, reject) => {
      this.getOneByTypeAndId("user", userId)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /**
   * Resolves the given path alias.
   *
   * @param {string} pathAlias
   *   The path alias to resolve.
   *
   * @returns {Promise<EtagenDrupalApi>}
   */
  resolveAlias(pathAlias) {
    return new Promise((resolve, reject) => {
      EtagenApi.callEndpoint(`resolve-path-alias/${pathAlias}`)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export { EtagenDrupalApi };
