let DevelopmentConfig = {
  debug: true,
  api: {
    base_url: "",
    auth: {
      username: "",
      password: ""
    },
    version: 1,
    layer: "",
    preprocessed: true,
    resolveAssociated: true
  },
  cache: {
    enabled: true
  },
  theme: "default"
};

export default DevelopmentConfig;
