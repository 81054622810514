<template>
  <div class="fixed-bottom-buttons">
    <div class="float-left">
      <a class="rotate-90 font-weight-bold" href="/kontakt">KONTAKT</a>
    </div>
    <div class="float-right">
      <a class="font-weight-bold" href="https://www.instagram.com/die_etagen/" target="_blank" >IG</a>
      <a class="font-weight-bold" href="https://www.facebook.com/dieetagen/" target="_blank">FB</a>
      <a class="font-weight-bold" href="https://www.linkedin.com/company/die-etagen-gmbh/" target="_blank">IN</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "FixedBottomButtons"
};
</script>

<style scoped lang="scss">
.fixed-bottom-buttons {
  bottom: 7em;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  *{
    color: #ffffff;
    transition: all 0.3s;
    &:hover {
      color: #ffec41;
    }
  }
  .rotate-90 {
    display: block;
    transform: rotate(270deg);
  }
  .float-left{
    font-size: 1.5rem;
    position: fixed;
    bottom: 8em;
    z-index: 100;
    left: 0.8em;
  }
  .float-right{
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 7em;
    right: 4rem;
    z-index: 100;
    >*{
      padding-bottom: 0.5em;
    }
  }
}
@media screen and (max-width: 860px){
  .fixed-bottom-buttons{
    display: none;
  }
}
</style>