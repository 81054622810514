import Config from "../config";
import axios from "axios";
import { EtagenDrupalApi } from "./layer/drupal";

/**
 * Base object for the etagen API module.
 *
 * This object provides base functionalities like calling an API endpoint, creating the base URL, etc.
 *
 * @type {Object}
 */
const EtagenApi = {
  /**
   * The version for this API.
   *
   * This version number will be used when building the base URL.<br />
   * Example: Let's assume you use a version like<br />
   * <code>let version = 1;</code><br />
   * The version will now be used like<br />
   * <code>let url = "https://yourdomain.com/api/v" + version + "/some/fancyEndpoint";</code><br />
   * The version set here is just a default and can be overridden by adding api.version to the configuration object.
   *
   * @type {int}
   */
  version: 1,

  /**
   * The base URL.
   *
   * This attribute is just a placeholder when creating an instance of this object and will be built then.
   *
   * @type {string}
   */
  baseUrl: "",

  /**
   * The axios config will be saved here when creating an instance of this object.
   *
   * @type {Object}
   */
  axiosConfig: {},

  /**
   * Sets the version the API should use.
   *
   * @param {int} version
   *   The version number as integer.
   *
   * @returns {EtagenApi}
   *   This object.
   */
  setVersion(version) {
    this.version = version;
    return this;
  },

  /**
   * Returns the version used for this API.
   *
   * @returns {Number}
   *   The currently used API version.
   */
  getVersion() {
    return this.version;
  },

  /**
   * Returns the base URL used to communicate with the API.
   *
   * @returns {string}
   *   The currently used base URL.
   */
  getBaseUrl() {
    return this.baseUrl;
  },

  /**
   * Sets the base URL to use.
   *
   * @param {string} baseUrl
   *   The base URL.
   *
   * @returns {EtagenApi}
   *   This object.
   */
  setBaseUrl(baseUrl) {
    this.baseUrl = baseUrl;
    return this;
  },

  /**
   * Returns the configuration object to use as default for axios.
   *
   * The config will be set in this.bootstrapByConfig().
   *
   * @returns {Object}
   *   The axios configuration.
   *
   * @see EtagenApi.bootstrapByConfig
   */
  getAxiosConfig() {
    return this.axiosConfig;
  },

  /**
   * Method to set a new axios configuration.
   *
   * @param {Object} axiosConfig
   *   The new axios config.
   *
   * @returns {EtagenApi}
   *   This object.
   */
  setAxiosConfig(axiosConfig) {
    this.axiosConfig = axiosConfig;
    return this;
  },

  /**
   * Reads the config and sets some attributes like the base URL and the axios config.
   */
  bootstrapByConfig() {
    // Read the config.
    let config = Config.api;

    // If an API version is set in Config.api.version we will set EtagenApi.version to that.
    if (config.version !== undefined && config.version !== null) {
      this.setVersion(config.version);
    }

    // Set the base URL based on the domain (Config.api.base_url) and the API version (EtagenApi.version).
    this.setBaseUrl(`${config.base_url}/api/v${this.getVersion()}/`);

    // Set the default axios configuration.
    this.setAxiosConfig({
      method: "post",
      headers: {
        Authorization: `Basic ${btoa(config.auth.username + ":" + config.auth.password)}`,
        "Accept-Encoding": "deflate",
        "Content-Type": "application/json;charset=UTF-8"
      }
    });
  },

  /**
   * Calls the given endpoint and returns a promise to resolve the fetched data.
   *
   * @param {string} endpoint
   *   The endpoint to call.<br />
   *   Example:<br />
   *   Lets's assume you have an endpoint like this:<br />
   *   <code>let endpoint = "some/fancyEndpoint";</code><br />
   *   This endpoint will be placed in the full URL like this:<br />
   *   <code>let url = "https://yourdomain.com/api/v1/" + endpoint;</code>
   * @param {Object} body
   *   Additional body data.
   *   To get an idea of what you can pass, have a look at the API documentation.
   *
   * @returns {Promise<axios>}
   *   A promise that will resolve the fetched data in the moment it returns.
   */
  callEndpoint(endpoint, body = {}, bypassApi = false) {
    // Load the currently used axios configuration.
    let axiosConfig = this.getAxiosConfig();

    // Set the url that has to be called.
    axiosConfig.url = `${this.getBaseUrl()}${endpoint}`;

    if (bypassApi) {
      axiosConfig.url = `${Config.api.base_url}/${endpoint}`;
    }

    let data = body;
    if (Config.api.preprocessed !== undefined) {
      data.preprocessed = Config.api.preprocessed;
    }

    if (Config.api.resolveAssociated !== undefined) {
      data.resolveAssociated = Config.api.resolveAssociated;
    }

    axiosConfig.data = data;

    return new Promise((resolve, reject) => {
      axios(axiosConfig)
        .then(result => {
          resolve(result.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export { EtagenApi, EtagenDrupalApi };
