import Vue from "vue";
import VueRouter from "vue-router";
//import Content from "../views/Content";
//import Taxonomy from "../views/Taxonomy";
// import Contact from "@/views/Contact";
// import Search from "@/views/Search";
// import Test from "@/views/Test";
// import Proxy from "@/views/Proxy";

Vue.use(VueRouter);

const routes = [
  {
    path: "/test",
    name: "Test",
    component: function() {
      return import(/* webpackChunkName: "test" */ "../views/Test.vue");
    }
  },
  {
    path: "/kontakt",
    name: "Kontakt",
    component: function() {
      return import(/* webpackChunkName: "kontakt" */ "../views/Contact.vue");
    }
  },
  {
    path: "/suche",
    name: "Suche",
    component: function() {
      return import(/* webpackChunkName: "suche" */ "../views/Search.vue");
    },
    props: route => ({ q: route.query.suche })
  },
  {
    path: "/",
    name: "Content",
    component: function() {
      return import(/* webpackChunkName: "content" */ "../views/Proxy.vue");
    }
  },
  {
    path: "/jobs",
    name: "Jobs",
    component: function() {
      return import(/* webpackChunkName: "jobs" */ "../views/Jobs.vue");
    }
  },
  {
    path: "/:l*",
    name: "Content With Params",
    component: function() {
      return import(/* webpackChunkName: "content-with-params" */ "../views/Proxy.vue");
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  let matches = to.path.match(/^\/api/g);
  if (matches !== null) {
    next(false);
  } else {
    next();
  }
});

export default router;
