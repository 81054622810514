<template>
  <div>

  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "CookieBanner",
  created() {
    this.cookieBanner();
    $(document).one('etCookiesAllowed-analytics', function () {
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-W7QL42T');
      // (function(l,e,a,d,i,n,f,o){if(!l[i]){l.GlobalLeadinfoNamespace=l.GlobalLeadinfoNamespace||[];l.GlobalLeadinfoNamespace.push(i);l[i]=function(){(l[i].q=l[i].q||[]).push(arguments)};l[i].t=l[i].t||n;l[i].q=l[i].q||[];o=e.createElement(a);f=e.getElementsByTagName(a)[0];o.async=1;o.src=d;f.parentNode.insertBefore(o,f);}}(window,document,"script","https://cdn.leadinfo.net/ping.js","leadinfo","LI-5FA1807315286"));
      (function(l,e,a,d,i,n,f,o){if(!l[i]){l.GlobalLeadinfoNamespace=l.GlobalLeadinfoNamespace||[];l.GlobalLeadinfoNamespace.push(i);l[i]=function(){(l[i].q=l[i].q||[]).push(arguments)};l[i].t=l[i].t||n;l[i].q=l[i].q||[];o=e.createElement(a);f=e.getElementsByTagName(a)[0];o.async=1;o.src=d;f.parentNode.insertBefore(o,f);}}(window,document,"script","https://cdn.leadinfo.net/ping.js","leadinfo","LI-634529722CF60"));
    });
  },
  methods: {
    cookieBanner() {
      let opt = {
          title: "Cookie-Einstellungen",
          mainText:
            "Wir verwenden Cookies, um Ihnen ein optimales Webseiten-Erlebnis zu bieten. Dazu zählen Cookies, die für den Betrieb der Seite und für die Steuerung unserer kommerziellen Unternehmensziele notwendig sind, sowie solche, die lediglich zu anonymen Statistikzwecken, für Komforteinstellungen oder zur Anzeige personalisierter Inhalte genutzt werden. Sie können selbst entscheiden, welche Kategorien Sie zulassen möchten. Bitte beachten Sie, dass auf Basis Ihrer Einstellungen womöglich nicht mehr alle Funktionalitäten der Seite zur Verfügung stehen. Weitere Informationen finden Sie in unseren <a href='/datenschutz'>Datenschutzhinweisen</a>.",
          showDetails: "Details anzeigen",
          hideDetails: "Details ausblenden",
          accept: "Auswahl bestätigen",
          acceptAll: "Alle auswählen",
          blockedContentShow: "Inhalt anzeigen",
          blockedContentSettings: "Einstellungen",
          showBannerLinkSelector: 'a[href*="#cookie-settings"], .show-cookie-settings, .show-et-cookie-settings',
          expireAfterDays: 30,
          bannerCookieName: "allowedCookies",
          acceptAllBtnClasses: "button btn",
          // ACHTUNG!
          // Alle folgenden Optionen sind Arrays oder Objekte, welche bei Anpassungen über das options-Objekt komplett überschrieben werden
          // Wenn man in einem der Arrays etwas anpasst, muss man auch alle seine Optionen in das options-Objekt übernehmen, die man beibehalten möchte.
          hideOnPages: ["/impressum", "/datenschutz"],
          kinds: [
            {
              kind: "necessary",
              title: "Notwendig",
              disabled: true,
              checked: true,
              details:
                "Diese Cookies sind notwendig, um den Betrieb unserer Seite für Sie reibungslos möglich zu machen."
            },
            {
              kind: "media",
              title: "Externe Medien",
              details:
                "Inhalte von Videoplattformen und Social-Media-Plattformen werden blockiert. Wenn Cookies von externen Medien akzeptiert werden, bedarf der Zugriff auf diese Inhalte keiner manuellen Einwilligung mehr."
            },
            {
              kind: "analytics",
              title: "Komfort",
              details:
                "Mit unserer Komforteinstellung bestätigen Sie die Nutzung von Cookies zur Verbesserung unserer Angebote. Hierbei können wir zum Beispiel einsehen, wer sich auf unserer Seite wo bewegt, um Inhalte noch einfacher für Sie darstellen zu können."
            },
            {
              kind: "personal",
              title: "Personalisierung",
              needs: ["analytics"],
              popupInfo: "Personalisierung benötigt auch die Kategorie Komfort.",
              details:
                "Mit der Zustimmung zur Nutzung von Informationen zum personalisierten Nutzerverhalten, können wir Ihnen noch besser relevante Inhalte für Sie ausspielen. Sie helfen uns mit der Bestätigung unwichtige Inhalte für Sie auszublenden, so dass Sie nur noch die für Sie wichtigen Informationen angezeigt bekommen."
            }
          ],
          // Falls bereits ein Cookiebanner lief und alte Cookie-Art-Bezeichnungen jetzt anders heißen.
          rename: {
            //komfort: "analytics"
          },
          deleteCookies: [],
          contentBlockers: [
            {
              selector: "iframe[src*='youtube-nocookie.com'], .et-cookie-set-blocker",
              kind: "media",
              text: "<p>Dieser Inhalt setzt Cookies für externe Medien. Sie hatten dem widersprochen.</p>",
              showBlockerWithoutHover: true
            }
          ],
          links: [
            {
              label: "Impressum",
              link: "/impressum"
            }
          ]
        },
        etCookieOptions = {
          title: "Cookie-Einstellungen",
          mainText:
            "Wir verwenden Cookies, um Ihnen ein optimales Webseiten-Erlebnis zu bieten. Dazu zählen Cookies, die für den Betrieb der Seite und für die Steuerung unserer kommerziellen Unternehmensziele notwendig sind, sowie solche, die lediglich zu anonymen Statistikzwecken, für Komforteinstellungen oder zur Anzeige personalisierter Inhalte genutzt werden. Sie können selbst entscheiden, welche Kategorien Sie zulassen möchten. Bitte beachten Sie, dass auf Basis Ihrer Einstellungen womöglich nicht mehr alle Funktionalitäten der Seite zur Verfügung stehen. Weitere Informationen finden Sie in unseren <a href='/datenschutz'>Datenschutzhinweisen</a>.",
          showDetails: "Details anzeigen",
          hideDetails: "Details ausblenden",
          accept: "Individuelle Auswahl bestätigen",
          acceptAll: "Alle auswählen",
          bannerCookieName: "allowedCookies",
          hideOnPages: ["/impressum", "/datenschutz"],
          kinds: [
            {
              kind: "necessary",
              title: "Essenziell",
              disabled: true,
              checked: true,
              details:
                "Essenzielle Cookies ermöglichen grundlegende Funktionen und sind für die einwandfreie Funktion der Website erforderlich."
            },
            {
              kind: "analytics",
              title: "Statistiken",
              details:
                "Statistik Cookies erfassen Informationen anonym. Diese Informationen helfen uns zu verstehen, wie unsere Besucher unsere Website nutzen."
            }
          ],
          deleteCookies: [],
          //contentBlockers: [],
          links: [
            {
              label: "Impressum",
              link: "/impressum"
            }
          ]
        };
      let i;
      $.fn.etagenCookie = function(options) {
        // Verhindern, dass der Banner doppelt initialisiert wird
        if ($(".et-cookie").length) {
          return;
        }

        if (typeof options !== "undefined") {
          $.extend(opt, options);
        }

        // Banner-Grundstruktur einsetzen
        this.first().prepend(
          "<div class='et-cookie'>" +
            "  <div class='et-cookie__main'>" +
            "    <svg style='display:none;'>" +
            "      <symbol fill='currentColor' id='et-cookie__icon-right'>" +
            "        <path d='M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z'/>" +
            "      </symbol>" +
            "    </svg>" +
            "    <h2>" +
            opt.title +
            "</h2>" +
            "    <p class='et-cookie__main-text'>" +
            opt.mainText +
            "</p>" +
            "    <div class='et-cookie__checkboxes'>" +
            "      <div class='et-cookie__checkbox-container'></div>" +
            "      <button type='button' class='et-cookie__no-btn et-cookie__accept-current'>" +
            opt.accept +
            "      </button>" +
            "    </div>" +
            "    <div class='et-cookie__popup-infos'></div>" +
            "    <div class='et-cookie__details et-cookie__details--hide'>" +
            "      <button type='button' class='et-cookie__no-btn et-cookie__show_details'>" +
            "        <span>" +
            opt.showDetails +
            "</span>" +
            "        <span>" +
            opt.hideDetails +
            "</span>" +
            "      </button>" +
            "      <div class='et-cookie__details-text'></div>" +
            "    </div>" +
            "    <div class='et-cookie__main-actions'>" +
            "      <button type='button' class='" +
            opt.acceptAllBtnClasses +
            " et-cookie__accept-all'>" +
            opt.acceptAll +
            "      </button>" +
            "    </div>" +
            "    <div class='et-cookie__links'>" +
            "    </div>" +
            "  </div>" +
            "</div>"
        );

        // Auswahlmöglickeiten einfügen
        for (i = 0; i < opt.kinds.length; i++) {
          let obj = opt.kinds[i],
            item = $(
              "<label class='et-cookie__checkbox-item'><input type='checkbox' class='et-cookie__option option-" +
                obj.kind +
                "' name='cookie-option' value='" +
                obj.kind +
                "'><span class='et-cookie__checkbox-label-text'>" +
                obj.title +
                "</span></label>"
            ),
            details = $("<h4>" + obj.title + "</h4><p>" + obj.details + "</p>"),
            check = item.find("input"),
            info = $("");
          if (obj.popupInfo && obj.popupInfo.length) {
            info = $('<p class="et-cookie__info-for-' + obj.kind + '">' + obj.popupInfo + "</p>");
          }
          if (obj.disabled) {
            item
              .addClass("et-cookie__disabled")
              .find("input")
              .prop("disabled", true);
          }
          if (obj.checked) {
            item.find("input").prop("checked", true);
          }
          if (obj.checked && obj.disabled) {
            item.find("input").addClass("et-cookie__always-checked", true);
          }
          $(".et-cookie__checkbox-container").append(item);
          $(".et-cookie__details-text").append(details);
          $(".et-cookie__popup-infos").append(info);
          // Popupinfos ein-/ausblenden
          check.change(function() {
            $(".et-cookie__popup-infos p").slideUp();
            if (check.prop("checked")) {
              info.slideDown();
            }
          });
          // Abhängigkeiten einrichten
          if (obj.needs && obj.needs.length) {
            for (let j = 0; j < obj.needs.length; j++) {
              let need = obj.needs[j],
                neededCheck = $(".et-cookie .option-" + need);
              check.change(function() {
                if (check.prop("checked")) {
                  neededCheck.prop("checked", true);
                }
              });
              neededCheck.change(function() {
                if (!neededCheck.prop("checked")) {
                  check.prop("checked", false);
                }
              });
            }
          }
        }

        // Footer-Links einfügen
        for (i = 0; i < opt.links.length; i++) {
          let link = opt.links[i],
            item = $("<a href='" + link.link + "' target='_blank'>" + link.label + "</a>");
          $(".et-cookie__links").append(item);
        }

        // Button-Icons einfügen
        $(".et-cookie button").append(
          "<i class='et-cookie__icon'>" +
            "  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'>" +
            "    <use xlink:href='#et-cookie__icon-right'/>" +
            "  </svg>" +
            "</i>"
        );

        // Funktion beim Klick des Buttons "Details anzeigen"
        $(".et-cookie__show_details").click(function() {
          $(".et-cookie__details").toggleClass("et-cookie__details--hide");
          $(".et-cookie__details-text").slideToggle();
        });

        // Funktion beim Klick des Button "Auswahl bestätigen"
        $(".et-cookie__accept-current").click(function() {
          acceptBannerSettings();
        });

        // Funktion beim Klick des Button "Alle auswählen"
        $(".et-cookie__accept-all").click(function() {
          checkAllSequentiallyAndAccept();
        });

        // Link Funktion zum erneuten Aufrufen des Cookie-Banners
        $("body").on("click", opt.showBannerLinkSelector, function(e) {
          e.preventDefault();
          showBanner();
        });

        insertContentBlockers();
        let bannerCookieExists = getCookie(opt.bannerCookieName).length;
        // Ausgewählte Cookies nur entfernen, wenn Optionen noch nicht gewählt wurden
        if (!bannerCookieExists && opt.deleteCookies.length) {
          deleteCookies(opt.deleteCookies);
        }
        activateAllFromCookie();
        // Cookiebanner einblenden, wenn Optionen noch nicht gewählt wurden und man sich nicht auf der Datenschutzseite oä. befindet
        if (!bannerCookieExists && !isHidePage()) {
          showBanner();
        }

        return this;
      };

      function checkAllSequentiallyAndAccept() {
        let uncheckedInputs = $(".et-cookie input:not(:disabled):not(:checked)");
        uncheckedInputs.first().prop("checked", true);
        if (uncheckedInputs.length > 1) {
          setTimeout(checkAllSequentiallyAndAccept, 200);
        } else {
          setTimeout(acceptBannerSettings, 200);
        }
      }

      function isHidePage() {
        for (let i = 0; i < opt.hideOnPages.length; i++) {
          let path = opt.hideOnPages[i];
          if (window.location.href.indexOf(path) > -1) {
            return true;
          }
        }
        return false;
      }

      function showBanner() {
        $(".et-cookie").fadeIn();
      }

      // Cookiebanner ausblenden
      function hideBanner() {
        $(".et-cookie").fadeOut();
      }

      // Ausgewählte Optionen im Banner übernehmen, Banner schließen
      function acceptBannerSettings() {
        hideBanner();
        updateBannerCookie();
        activateAllFromCookie();
      }

      // Unerwünschte Cookies, z.B. veraltete BannerCookies entfernen
      function deleteCookies(cookieArray) {
        for (let i = 0; i < cookieArray.length; i++) {
          document.cookie = cookieArray[i] + "= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
        }
      }

      function updateBannerCookie() {
        let kinds = [];
        $(".et-cookie input:checked").each(function() {
          kinds.push($(this).val());
        });
        setCookie(opt.bannerCookieName, JSON.stringify(kinds), opt.expireAfterDays);
      }

      // Auf Basis des CookiebannerCookies scripts/iFrames aktivieren und Häkchen anhaken
      function activateAllFromCookie() {
        let allowedCookies = getCookie(opt.bannerCookieName);
        if (allowedCookies.length > 0) {
          allowedCookies = JSON.parse(allowedCookies);
          $(".et-cookie-blocker")
            .prev()
            .addClass("et-cookie-has-blocker");
          $(".et-cookie input:not(.et-cookie__always-checked)").prop("checked", false);
          $.each(allowedCookies, function() {
            // Alte oder neue Cookies auslesen
            // +'' , weil sonst String{"kind"} ausgegeben wird, was ua. nicht === "kind" ist.
            let kind = this + "";
            kind = opt.rename[kind] ? opt.rename[kind] : kind;
            activateAllByKind(kind);
          });
        }
      }

      function activateAllByKind(kind) {
        // Scripts sind durch type="text/plain" inaktiv.
        // Sie müssen die Klassen "cookiePermission" und eines der "kinds" haben (z.B. "analytics")
        // Das funktioniert nur, wenn das js ein src hat, nicht mit inline-Javascript!
        activateScripts("script.cookiePermission." + kind);
        // iFrames sind durch src="about:blank" inaktiv.
        // Sie müssen die Klassen "cookiePermission" und eines der "kinds" haben (z.B. "analytics")
        // der eigentliche src muss im "data-src"-Attribut stehen
        activateIframes("iframe.cookiePermission." + kind);
        // Event für individuelle Cookie-Funktionen
        // Per 2. Parameter kann man verhindern, dass mehrfach dieselbe Funktion ausgeführt wird.
        // z.B. so nutzen:
        // $(document).on('etCookiesAllowed', function(e, kind, repeat){
        //   if (kind === 'analytics' && !repeat) { ... }
        // });
        $(document).trigger("etCookiesAllowed", [kind, wasAllowedBefore(kind)]);
        // Alternativ dieses Event nutzen, welches für jede Cookie-Art anders lautet.
        // Wiederholungen lassen sich hier einfach per .one() statt .on() vermeiden.
        // z.B.:
        // $(document).one('etCookiesAllowed-analytics', function(e) { ... });
        $(document).trigger("etCookiesAllowed-" + kind);
        // Bei der ersten Freigabe einer Cookie-Art wird eine passende Klasse gesetzt.
        // ACHTUNG: bleibt absichtlich auch bestehen, wenn die Freigabe wieder zurückgenommen wird
        $("html").addClass("et-cookies-was-allowed-" + kind);
        // Haken im Banner setzen
        $('.et-cookie input[value="' + kind + '"]').prop("checked", true);
      }

      function wasAllowedBefore(kind) {
        return !!$("html").is(".et-cookies-was-allowed-" + kind);
      }

      // Hilfsfunktion: Existiert die verwendete Cookie-Art überhaupt als Auswahlmöglichkeit?
      function kindExists(kind) {
        for (let i = 0; i < opt.kinds.length; i++) {
          if (opt.kinds[i].kind === kind) {
            return true;
          }
        }
        return false;
      }

      function insertContentBlockers() {
        for (let i = 0; i < opt.contentBlockers.length; i++) {
          let obj = opt.contentBlockers[i],
            showOnHover = obj.showBlockerWithoutHover ? "" : "et-show-on-hover",
            blocker = $(
              '<div class="et-cookie-blocker ' +
                obj.kind +
                " " +
                showOnHover +
                '">' +
                obj.text +
                '<button class="et-cookie-blocker--show-content btn button">' +
                opt.blockedContentShow +
                '</button><button class="et-cookie-blocker--show-settings show-et-cookie-settings btn button">' +
                opt.blockedContentSettings +
                "</button></div>"
            );
          // Blocker nur einbinden, wenn die genannte Cookie-Art überhaupt auswählbar ist,
          // oder wenn der Blocker explizit vom Banner unabhängig sein soll.
          if (kindExists(obj.kind) || obj.kind === "standalone-blocker") {
            $(obj.selector)
              .filter("*:not(.et-cookie-has-blocker)")
              .addClass("et-cookie-has-blocker")
              .after(blocker)
              .each(function() {
                let elem = $(this),
                  blocker = elem.next();
                blocker.find(".et-cookie-blocker--show-content").click(function() {
                  // activateIframes(elem);
                });
              });
          }
        }
      }

      function activateScripts(scripts) {
        $(scripts).each(function() {
          let script = $("<script />");
          script.attr("src", $(this).attr("src"));
          $(this)
            .before(script)
            .remove();
        });
      }

      function activateIframes(iframes) {
        $(iframes)
          .removeClass("et-cookie-has-blocker")
          .each(function() {
            let iframe = $(this),
              dataSrc = iframe.data("src");
            if (dataSrc && iframe.attr("src") !== dataSrc) {
              iframe.attr("src", dataSrc);
            }
          });
      }

      // Hilfsfunktion: Cookie-Setter
      function setCookie(cname, cvalue, exdays) {
        let d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/ ; SameSite=lax";
      }

      // Hilfsfunktion: Cookie-Getter
      function getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(";");
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === " ") {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }

      // Banner automatisch initialisieren, falls die Variable "etCookieOptions" bereits vorliegt
      $(document).ready(function() {
        if (typeof etCookieOptions !== "undefined") {
          $("body").etagenCookie(etCookieOptions);
        }
      });
    }
  }
};
</script>

<style lang="scss">
.et-cookie,
.et-cookie * {
  box-sizing: border-box;
}

.et-cookie {
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  overflow-y: auto;
  padding: 1em;
  background-color: rgba(0, 0, 0, 0.6);
}
.et-cookie__main {
  position: relative;
  max-width: 58em;
  margin: 0 auto;
  padding: 3em;
  background: white;
  color: #676767;
}
@media (max-width: 700px) {
  .et-cookie__main {
    padding: 1em;
  }
}

.et-cookie h2 {
  margin-top: 0;
  padding-top: 0;
}
.et-cookie__links a {
  margin-right: 1em;
}

/*************************************************
Checkboxen
*************************************************/
.et-cookie__checkboxes {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 1em 0;
}
.et-cookie__checkbox-container {
  display: flex;
  flex: 1 0 auto;
  flex-wrap: wrap;
  max-width: 100%;
}
.et-cookie__checkbox-item {
  position: relative;
  display: block;
  flex: 0 0 auto;
  width: auto;
  margin: 0;
  margin-right: 2.5em;
  padding: 0.5em 0;
  border: 0;
}
.et-cookie__checkbox-item input[type="checkbox"] {
  position: static;
  float: none;
  margin: 0;
  margin-right: 1em;
  border: 1px solid;
}
.et-cookie__disabled .et-cookie__checkbox-label-text {
  opacity: 0.5;
}
.et-cookie__checkbox-item:not(.et-cookie__disabled) {
  cursor: pointer;
}
@media (max-width: 600px) {
  .et-cookie__checkbox-item {
    min-width: 60%;
  }
}

.et-cookie__popup-infos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.et-cookie__popup-infos p {
  display: none;
  border: 1px solid;
  margin-top: 0;
  padding: 0.5em;
}

/*************************************************
Detail-Texte
*************************************************/
.et-cookie__details-text {
  overflow: hidden;
  margin-top: 1em;
}
.et-cookie__details-text,
.et-cookie__details--hide .et-cookie__show_details * + span,
.et-cookie__details:not(.et-cookie__details--hide) .et-cookie__show_details span:first-child {
  display: none;
}

/*************************************************
Buttons
*************************************************/
.et-cookie button,
.et-cookie-blocker button {
  font: inherit;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  margin: 0;
  cursor: pointer;
}
button.et-cookie__no-btn {
  padding: 0.5em 0;
  color: inherit;
  border: 0;
  background: 0;
}

.et-cookie__show_details {
  outline: none;
}

.et-cookie__main-actions {
  display: flex;
  justify-content: flex-end;
  margin: 2em 0 1em;
  button{
    color:#676767;
  }
}
button.et-cookie__accept-all {
  font-size: 1.3em;
}

/*************************************************
Button-Icons
*************************************************/
.et-cookie__icon {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-left: 0.3em;
}
.et-cookie__icon svg {
  max-width: 100%;
  max-height: 100%;
}
.et-cookie__show_details .et-cookie__icon svg {
  transform: rotate(270deg);
}
.et-cookie__details--hide .et-cookie__icon svg {
  transform: rotate(90deg);
}

/*************************************************
Content-Blocker
*************************************************/
.et-cookie-has-blocker {
  pointer-events: none;
}
.et-cookie-blocker {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow: auto;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 1em;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
}
*:not(.et-cookie-has-blocker) + .et-cookie-blocker {
  display: none;
}
.et-cookie-blocker button + button {
  margin-top: 0.5em;
}
.et-cookie-blocker > * {
  transition: 0.3s;
}
.et-cookie-blocker.et-show-on-hover:not(:hover) > * {
  transform: translateY(1em);
  opacity: 0;
}
</style>
