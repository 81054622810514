<template>
  <div class="navigation-sidebar">
    <div v-for="(point, key) in points" :key="key" class="point">
      <span class="nsb-dot" :data-jump-to="point.href" v-on:click="jump"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationSidebar",
  data() {
    return {
      indicatorClassName: "nsb-section",
      points: []
    };
  },
  methods: {
    findSections() {
      return document.getElementsByClassName(this.indicatorClassName);
    },
    countSections() {
      return this.findSections().length;
    },
    handleScroll() {
      let viewHeight = window.innerHeight;
      let middleLine = viewHeight / 2;

      let nsbTargets = this.findSections();

      nsbTargets.forEach(target => {
        let position = this.getPosition(target);
        let posTop = position.y;
        let posBottom = posTop + target.offsetHeight;

        let isPresent = posTop < middleLine && posBottom > middleLine;

        let identifier = target.getAttribute("data-nsb-target");
        let dots = document.getElementsByClassName("nsb-dot");

        dots.forEach(dot => {
          if (dot.getAttribute("data-jump-to") === identifier) {
            if (isPresent) {
              dot.classList.add("active");
            } else {
              dot.classList.remove("active");
            }
          }
        });
      });
    },
    getPosition(el) {
      var xPos = 0;
      var yPos = 0;

      while (el) {
        if (el.tagName === "BODY") {
          // deal with browser quirks with body/window/document and page scroll
          var xScroll = el.scrollLeft || document.documentElement.scrollLeft;
          var yScroll = el.scrollTop || document.documentElement.scrollTop;

          xPos += el.offsetLeft - xScroll + el.clientLeft;
          yPos += el.offsetTop - yScroll + el.clientTop;
        } else {
          // for all other non-BODY elements
          xPos += el.offsetLeft - el.scrollLeft + el.clientLeft;
          yPos += el.offsetTop - el.scrollTop + el.clientTop;
        }

        el = el.offsetParent;
      }
      return {
        x: xPos,
        y: yPos
      };
    },
    jump(event) {
      if (event.target === undefined || event.target === null) {
        return;
      }

      if (!event.target.hasAttribute("data-jump-to")) {
        return;
      }

      if (event.target.classList.contains("active")) {
        return;
      }

      if (event.target.getAttribute("data-jump-to").match(/^nsb-/)) {
        let target = event.target.getAttribute("data-jump-to");
        let targetElement = document.querySelectorAll(`[data-nsb-target="${target}"]`)[0];

        let duration = 750;
        var startingY = window.pageYOffset;
        var diff = targetElement.offsetTop - startingY;
        var start;
        // Bootstrap our animation - it will get called right before next frame shall be rendered.
        window.requestAnimationFrame(function step(timestamp) {
          if (!start) start = timestamp;
          // Elapsed milliseconds since start of scrolling.
          var time = timestamp - start;
          // Get percent of completion in range [0, 1].
          var percent = Math.min(time / duration, 1);

          window.scrollTo(0, startingY + diff * percent);

          // Proceed with animation as long as we wanted it to.
          if (time < duration) {
            window.requestAnimationFrame(step);
          }
        });
      }
    },
    observerCallback(entries) {
      entries.forEach(entry => {
        let el = entry.target;

        this.determineTopOrBottom(el);
        if (entry.isIntersecting) {
          el.classList.remove("off-stage");
          el.classList.add("on-stage");
        } else {
          el.classList.remove("on-stage");
          el.classList.add("off-stage");
        }
      });
    },
    determineTopOrBottom(el) {
      const elBox = el.getBoundingClientRect();
      const elCenterPos = elBox.height / 2 + elBox.top;

      if (elCenterPos < this.halfViewportHeight) {
        el.classList.remove("at-bottom");
        el.classList.add("at-top");
      } else {
        el.classList.remove("at-top");
        el.classList.add("at-bottom");
      }
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
    window.setInterval(() => {
      this.points = [];
      let sections = this.findSections();
      sections.forEach((elem, key) => {
        elem.setAttribute("data-nsb-target", `nsb-${key}`);
        this.points.push({
          href: `nsb-${key}`
        });
      });
      this.handleScroll();
    }, 1000);

    this.observer = new IntersectionObserver(this.observerCallback, this.observerOptions);
  },
  updated: function () {
    const self = this;
    this.$nextTick(function () {
      self.fadeElms = document.querySelectorAll(".wrapper-row, .splide__slide");
      self.fadeElms.forEach(el => this.observer.observe(el));
    })
  },
  beforeDestroy() {
  this.observer.disconnect();
  }
};
</script>

<style scoped lang="scss">
@import "src/themes/default/index";
.navigation-sidebar {
  position: fixed;
  bottom: calc(100vh - 50%);
  transform: translateY(50%);
  left: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 14px;

  .point {
    padding: 0.5em 0;
    span {
      display: block;
      width: 7px;
      height: 7px;
      border-radius: 100px;
      background-color: $primary;
      transition: 0.3s;

      cursor: pointer;

      &.active {
        background-color: $warning;
        width: 14px;
        height: 14px;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .navigation-sidebar {
    display: none;
  }
}
</style>
