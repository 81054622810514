import Vue from "vue";
import {
  BButton,
  BCard,
  BCardGroup,
  BCardImgLazy,
  BCardText,
  BCarousel,
  BCarouselSlide,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BOverlay,
  BVToastPlugin,
} from "bootstrap-vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { Etagen } from "./etagen";
import VueMeta from "vue-meta";
import LazyLoadDirective from "./directives/LazyLoadDirective";
import VueGtag from "vue-gtag";

// Theming
// import the default theme as first;
Etagen.importThemes();
// Install BootstrapVue
 Vue.use(BVToastPlugin);

Vue.use(VueGtag, {
  config: { id: "UA-15458776-7" }
});

Vue.component("b-carousel", BCarousel);
Vue.component("b-carousel-slide", BCarouselSlide);
Vue.component("b-card", BCard);
Vue.component("b-card-text", BCardText);
Vue.component("b-card-group", BCardGroup);
Vue.component("b-form", BForm);
Vue.component("b-form-group", BFormGroup);
Vue.component("b-form-input", BFormInput);
Vue.component("b-input", BFormInput);
Vue.component("b-form-textarea", BFormTextarea);
Vue.component("b-overlay", BOverlay);
Vue.component("b-button", BButton);
Vue.component("b-card-img-lazy", BCardImgLazy);

// Use vue-meta (VueMeta) as meta tag manager
Vue.use(VueMeta);

Vue.config.productionTip = false;
Vue.directive("lazyload", LazyLoadDirective);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

String.prototype.truncate =
  String.prototype.truncate ||
  function(n, useWordBoundary) {
    if (this.length <= n) {
      return this;
    }
    const subString = this.substr(0, n - 1); // the original check
    return (useWordBoundary ? subString.substr(0, subString.lastIndexOf(" ")) : subString) + "&hellip;";
  };
