<template>
  <div class="header-bar">
    <div class="top-banner d-flex justify-content-center align-items-center">
      <ul class="nav align-items-center">
        <li class="nav-item title">
          <strong class="primary-color">
            Die Etagen Gruppe ist vielfältig:
          </strong>
        </li>
        <li class="nav-item">
          <a href="https://design-etage.com/" class="nav-link primary-color">Design-Etage</a>
        </li>
        <li class="nav-item">
          <a href="https://www.expo-etage.de/" class="nav-link primary-color">Expo-Etage</a>
        </li>
        <li class="nav-item">
          <a href="https://www.mobile-etage.de/mobile-etage" class="nav-link primary-color">Mobile-Etage</a>
        </li>
        <li class="nav-item">
          <a href="https://www.drehteam.de/" class="nav-link primary-color">Drehteam</a>
        </li>
        <li class="nav-item">
          <a href="https://www.hob-by-horse.de/" class="nav-link primary-color">Hob by Horse</a>
        </li>
        <li class="nav-item">
          <a href="https://www.dingsbums-agentur.de/" class="nav-link primary-color">Dingsbums - Das Kreativbüro</a>
        </li>
      </ul>
    </div>
    <div class="header-bar-inner">
      <div class="menu">
        <div class="logo">
          <!-- File located at vue/public/img/logo.svg -->
          <a @click="onClick" href="/">
            <img src="/img/logo.svg" alt="Die Etagen GmbH Logo" />
          </a>
        </div>
        <ul class="nav justify-content-center">
          <li v-for="(link, index) in links" :key="index" class="nav-item">
            <a @click="onClick" v-if="link.internal" class="nav-link primary-color " :href="link.path">{{
              link.name
            }}</a>
            <a
              target="_blank"
              @click="onClick"
              v-if="!link.internal"
              class="nav-link primary-color "
              :href="link.path"
              >{{ link.name }}</a
            >
          </li>
          <li class="menu-button">
            <transition name="fade" mode="in-out">
              <button
                class="btn btn-gradient"
                v-on:click="toggleMenu"
                id="menu-toggle-button"
                variant="outline-dark"
                v-bind:key="clicked"
              >
                {{ buttonMessage }}
              </button>
            </transition>
          </li>
          <li class="mobile-button">
            <button class="menu-toggle" @click="toggleMenu"><span class="burger-icon"></span></button>
          </li>
        </ul>
      </div>
    </div>
    <div id="header-bar-full-menu" class="header-bar-full-menu hidden">
      <!-- search bar -->
      <div class="search">
        <b-form @submit.prevent="onSubmit">
          <!-- TODO Icon -->
          <b-input type="text" v-model="form.query" placeholder="Suche" :disabled="showSearch"></b-input>
          <button variant="primary" type="submit">
            <svg
              version="1.1"
              id="Ebene_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 31.1 31.1"
              style="enable-background:new 0 0 31.1 31.1;"
              xml:space="preserve"
            >
              <path
                class="st0"
                d="M28.5,28.1L22,21.6c3.6-4.3,3.4-10.8-0.7-14.8C17,2.5,10.1,2.5,5.8,6.8c-4.3,4.3-4.3,11.3,0,15.5
	c2.1,2.1,5,3.2,7.8,3.2c2.5,0,5-0.9,7-2.5l6.5,6.5L28.5,28.1z M7.2,20.9c-3.5-3.5-3.5-9.2,0-12.7c1.8-1.8,4.1-2.6,6.4-2.6
	c2.3,0,4.6,0.9,6.4,2.6c3.5,3.5,3.5,9.2,0,12.7C16.4,24.4,10.7,24.4,7.2,20.9z"
              />
            </svg>
          </button>
        </b-form>
      </div>
      <!-- menu structure -->
      <ul class="inner-menu nav">
        <li v-for="(link, index) in links" :key="index" class="nav-item">
          <b class="xbold">
            <a @click="onClick" v-if="link.internal" class="nav-link" :href="link.path">{{ link.name }}</a>
            <a target="_blank" @click="onClick" v-if="!link.internal" class="nav-link" :href="link.path">{{
              link.name
            }}</a>
          </b>
          <!-- children have the same structure as their parents ("name" + "path") -->
          <div v-for="(child, childIndex) in link.children" :key="childIndex">
            <a @click="onClick" v-if="link.internal" :href="child.path">{{ child.name }}</a>
            <a target="_blank" @click="onClick" v-if="!link.internal" :href="child.path">{{ child.name }}</a>
          </div>
        </li>
      </ul>

      <!-- suspicious button -->
      <div class="contact-button ">
        <a @click="onClick" href="/kontakt">
          <button class="btn btn-gradient text-uppercase" @click="toggleMenu" variant="outline-dark">
            Kontaktieren Sie uns
          </button>
        </a>
        <a @click="onClick" href="/jobs">
          <button class="btn btn-gradient text-uppercase" @click="toggleMenu" variant="outline-dark">
            Jobs
          </button>
        </a>
      </div>

      <div class="unit-menu py-4">
        <ul v-if="unitLinks" class="nav">
          <li class="font-weight-bold pl-3 text-white ">Wir sind vielfältig:</li>
          <li v-for="(link, index) in unitLinks" :key="index" class="nav-item">
            <a @click="onClick" v-if="link.internal" class="nav-link" :href="link.path">{{ link.name }}</a>
            <a target="_blank" @click="onClick" v-if="!link.internal" class="nav-link" :href="link.path">{{
              link.name
            }}</a>
          </li>
        </ul>
      </div>

      <div class="footer border-top">
        <div class="container address-os text-white">
          <b>Die Etagen GmbH</b><br />
          <b>Werbeagentur in Osnabrück</b><br />
          Natruper Str. 14<br />
          49076 Osnabrück
        </div>
        <div class="container address-hh text-white">
          <b>Die Etagen GmbH</b><br />
          <b>Werbeagentur in Hamburg</b><br />
          Beim Schlump 57<br />
          20144 Hamburg
        </div>
        <div class="container phone-email primary-color ">
          <a class="primary-color" href="tel:05416001550">0541&nbsp;6001550</a><br />
          <a class="primary-color" href="mailto:info@die-etagen.de">info@die-etagen.de</a><br />
          <a class="primary-color" href="tel:040999999070">040&nbsp;999999070</a><br />
          <a class="primary-color" href="mailto:hh@die-etagen.de">hh@die-etagen.de</a>
        </div>
        <div class="container fix-links primary-color ">
          <!-- TODO: Insert links -->
          <a class="primary-color" href="/impressum">Impressum</a><br />
          <a class="primary-color" href="/datenschutz">Datenschutz</a><br />
          <a class="primary-color" href="/agb">AGB</a>
        </div>
        <div class="container kununu">
          <!--          <a v-lazyload href="https://halocline.io" target="_blank">-->
          <!--            <img class="halocline" data-url="/img/halocline_logo_hell_v2.svg" alt="Die Etagen GmbH ist Halocline-Partner"/>-->
          <!--          </a>-->
          <a v-lazyload @click="onClick" href="#">
            <img data-url="/img/kununu2.png" alt="Die Etagen GmbH ist kununu TOP COMPANY" />
          </a>
          <a v-lazyload @click="onClick" href="#">
            <img data-url="/img/kununu1.png" alt="Die Etagen GmbH ist kununu OPEN COMPANY" />
          </a>
          <a v-lazyload @click="onClick" href="#">
            <img data-url="/img/badge-leadinfo.png" alt="LeadInfo Certified Partner" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EtagenDrupalApi } from "@/etagen-api";

export default {
  name: "HeaderBar",
  components: {},
  data() {
    return {
      links: null,
      unitLinks: null,
      form: {
        query: ""
      },
      clicked: false,
      showSearch: false
    };
  },
  created() {
    // Fetch main menu links
    EtagenDrupalApi.getByArgs("default", "menu", "all", "main").then(result => {
      if (result.status === "OK" && result.data.main !== undefined) {
        this.links = this.processMenuLinks(result.data.main);
      }
    });

    // Fetch unit menu links
    EtagenDrupalApi.getByArgs("default", "menu", "all", "units").then(result => {
      if (result.status === "OK" && result.data.units !== undefined) {
        this.unitLinks = this.processMenuLinks(result.data.units);
      }
    });
  },
  methods: {
    processMenuLinks(entries) {
      let links = [];
      for (let entriesKey in entries) {
        let internal = false;
        let raw = entries[entriesKey].raw_path;
        let regex = /^\/($|[^/])/;

        if (regex.test(raw)) {
          internal = true;
        }

        let link = {
          path: raw,
          name: entries[entriesKey].title,
          internal: internal
        };

        if (entries[entriesKey].children !== undefined) {
          let children = [];
          for (let childKey in entries[entriesKey].children) {
            let internalChild = false;
            if (regex.test(entries[entriesKey].children[childKey].raw_path)) {
              internalChild = true;
            }

            children.push({
              path: entries[entriesKey].children[childKey].raw_path,
              name: entries[entriesKey].children[childKey].title,
              internal: internalChild
            });
          }

          link.children = children;
        }
        links.push(link);
      }

      return links;
    },
    getIfMenuIsHidden() {
      return document.getElementById("header-bar-full-menu").classList.contains("hidden");
    },
    toggleMenu() {
      let fullMenu = document.getElementById("header-bar-full-menu");
      if (this.getIfMenuIsHidden()) {
        fullMenu.classList.remove("hidden");
        fullMenu.parentElement.classList.add("menu-on");
        // TODO: Icon
        this.clicked = true;
        document.documentElement.classList.add("menu-on");
      } else {
        fullMenu.classList.add("hidden");
        fullMenu.parentElement.classList.remove("menu-on");
        this.clicked = false;
        document.documentElement.classList.remove("menu-on");
      }
      console.log(window.location.pathname);
    },
    onSubmit() {
      //console.debug(event);
      // TODO: Implement search endpoint for api
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      window.localStorage.setItem("search", this.form.query);
      this.$router.push({ path: "suche", query: { suche: this.form.query } });
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      this.toggleMenu();
    },
    onClick() {
      if (!this.getIfMenuIsHidden()) {
        this.toggleMenu();
      }
    }
  },
  updated: function() {
    this.$nextTick(function() {
      (function() {
        var doc = document.documentElement,
          w = window,
          prevScroll = w.scrollY || doc.scrollTop,
          curScroll,
          direction = 0,
          prevDirection = 0,
          header = document.querySelector(".header-bar");

        var checkScroll = function() {
          curScroll = w.scrollY || doc.scrollTop;
          if (curScroll > prevScroll) {
            //scrolled up
            direction = 2;
          } else if (curScroll < prevScroll) {
            //scrolled down
            direction = 1;
          }

          if (direction !== prevDirection) {
            toggleHeader(direction, curScroll);
          }

          prevScroll = curScroll;
        };

        var toggleHeader = function(direction, curScroll) {
          if (direction === 2 && curScroll > 170) {
            header.classList.add("hide");
            prevDirection = direction;
          } else if (direction === 1) {
            header.classList.remove("hide");
            prevDirection = direction;
          }
        };

        window.addEventListener("scroll", checkScroll);
      })();
    });
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    buttonMessage() {
      switch (this.clicked) {
        case true:
          return "X";
        case false:
          return "MENÜ";
      }
    }
  },
  watch: {
    $route(to) {
      if (to.path === "/suche") {
        this.showSearch = true;
      } else {
        this.showSearch = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.header-bar:not(.menu-on) {
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.8);
  .header-bar-full-menu {
    display: none;
    transform: translateX(calc(100vw * -1));
  }
  transition: all 0.5s ease;
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
}
.header-bar:not(.menu-on).hide {
  top: -170px;
}
.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 0;

  .nav {
    flex: 1;
  }
}
.header-bar.menu-on {
  font-family: "Sen";
  display: flex;
  flex-direction: column;
  background: url("../assets/menu-background.png") no-repeat;
  background-size: cover;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 101;
  overflow-y: scroll;

  .header-bar-inner {
    flex: 1;
  }
  .search {
    form {
      display: flex;
      flex-direction: row;
    }
  }
  .header-bar-full-menu {
    max-width: 98rem;
    margin: 0 auto;
    width: 100vw;
    flex: 4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    animation: menuSlide 0.7s ease;

    .footer {
      display: flex;
      flex-wrap: wrap;
      padding: 1.5rem 1.5rem 1.5rem 0;
      > * {
        width: 20%;
        font-size: 1.1rem;
      }
    }
  }
  .unit-menu .nav {
    align-items: center;
    justify-content: space-between;
  }
  .mobile-button {
    .menu-toggle {
      right: 2em;
    }
  }
}
.unit-menu .nav {
  .nav-item a {
    color: #ffffff;
  }
}
.search {
  padding-bottom: 2em;
  form {
    position: relative;
    .st0 {
      fill: #676767;
    }
    align-items: center;
    input {
      background: #171717;
      border: none;
      height: 3em;
      &:focus {
        box-shadow: 0 0 0 0.1rem rgb(255 255 255);
      }
    }
    button {
      background: transparent;
      border: none;
      position: absolute;
      right: 1em;
      display: flex;
      align-items: center;
      justify-content: center;
      &:focus {
        outline: none;
      }
      svg {
        width: 1.3em;
      }
    }
  }
}
.menu,
.unit-menu {
  ul {
    .nav-item a {
      transition: all 0.3s;
      text-transform: uppercase;
      font-size: 1.3rem;
      &:hover {
        color: #ffec41;
        transition: all 0.3s;
      }
    }
  }
}
a.primary-color {
  &:hover {
    color: #ffec41;
  }
}
.top-banner {
  > * {
    font-size: 0.9rem;
  }
  background: #ffffff;
  a.primary-color {
    &:hover {
      color: #000000;
      text-decoration: underline;
    }
  }
}
.kununu img {
  max-height: 70px;
  margin: 0 0 1em 0.6em;
}
.inner-menu {
  justify-content: space-between;
  .nav-item {
    text-transform: uppercase;
    .xbold {
      font-size: 1.4rem;
    }
    a {
      padding: 0.5rem 1rem 1rem 0;
      color: #ffffff;
    }
    div {
      font-size: 1.1rem;
      padding-bottom: 0.5em;
    }
  }
}
.nav.justify-content-center {
  .nav-item {
    padding-right: 1.5em;
  }
}
.header-bar.menu-on > * {
  z-index: 1;
}
@keyframes backgroundOpacity {
  0% {
    background: rgba(0, 0, 0, 1);
  }
  100% {
    background: rgba(0, 0, 0, .8);
  }
}
.header-bar.menu-on:before {
  content: "";
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  animation: backgroundOpacity .5s ease forwards;

  .burger-icon {
    background: transparent;
  }
}
.menu-button {
  position: absolute;
  right: 5.2em;
  button {
    position: absolute;
    font-size: 1.3rem;
  }
}
.mobile-button {
  .menu-toggle {
    color: #ffffff;
    width: 4rem;
    height: 4rem;
    min-width: 0;
    background: 0;
    padding: 0;
    outline: none;
    display: none;
    border: none;
    z-index: 100;
    position: absolute;
    right: 2em;
    top: 3.4em;
    .burger-icon {
      position: absolute;
      width: 1.5rem;
      height: 2px;
      margin-top: -1px;
      display: block;
      background: #ffffff;
      transition: 0.5s;

      &:after,
      &:before {
        content: "";
        display: block;
        height: 2px;
        background: currentColor;
        position: absolute;
        top: -8px;
        left: 0;
        transition: top 0.07s cubic-bezier(0, 0, 0.58, 1), bottom 0.07s cubic-bezier(0, 0, 0.58, 1),
          transform 0.2s cubic-bezier(0, 0, 0.58, 1) 0.07s;
      }
      &:before {
        width: 2rem;
      }
      &:after {
        width: 2rem;
        top: auto;
        bottom: -8px;
      }
    }
  }
}
.contact-button {
  a:first-child {
    margin-right: 2em;
  }
  button {
    padding: 0.7em 1em;
  }
}
.logo {
  width: 5em;
  left: 2em;
  position: relative;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter {
  transform: translateX(-100px);
}
.fade-leave-to {
  transform: translateX(100px);
  opacity: 0;
}

@-webkit-keyframes menuSlide {
  0% {
    transform: translateX(calc(100vw * -1));
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@media screen and (max-width: 1200px) {
  .inner-menu {
    .nav-item {
      div {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .menu {
    > ul {
      > .nav-item {
        display: none;
      }
    }
  }
  .top-banner {
    .title {
      display: none;
    }
  }
  .header-bar.menu-on {
    .menu-toggle {
      .burger-icon {
        background: transparent;
        &:before {
          transform: rotate(-45deg);
          top: 0;
        }
        &:after {
          transform: rotate(45deg);
          bottom: 0;
        }
      }
    }
  }
  .menu-button {
    display: none;
  }
  .mobile-button {
    .menu-toggle {
      display: block;
      transform: rotate(180deg);
      .burger-icon {
        bottom: 1.2em;
      }
    }
  }
}
@media screen and (min-width: 861px) {
  .header-bar-full-menu {
    padding: 0 1rem;
  }
}
@media screen and (max-width: 850px) {
  .header-bar.menu-on {
    overflow-y: scroll;
    .inner-menu {
      justify-content: center;
    }
  }
  .contact-button {
    display: none;
  }
  .header-bar.menu-on {
    .header-bar-full-menu {
      .footer {
        > *:not(.kununu) {
          width: 50%;
          padding: 0 1em 2em 1em;
        }
        > .kununu {
          width: 100%;
          padding: 0 1em 2em 1em;
        }
      }
    }
  }
}
@media all and (max-width: 557px) {
  .mobile-button .menu-toggle {
    top: 5em;
  }
}

.halocline {
  max-height: 42px !important;
}
</style>
